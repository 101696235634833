import React from "react";
import { Space, Typography, Button, Divider, Row, Col } from "antd";
import { useNavigate } from "react-router-dom";
import { LeftOutlined } from "@ant-design/icons";

const CustomPageHeader = ({
    subTitle,
    title,
    backIcon = <LeftOutlined />, // Default back icon
    onBack,
    extra,
    className,
    key,
    divider,
    children,
}) => {
    const navigate = useNavigate();

    const handleBackClick = () => {
        if (onBack) {
            onBack();
        } else {
            navigate(-1);
        }
    };

    return (
        <div className={className} key={key} style={{ padding: "16px 0" }}>
            <Row align="middle" justify="space-between">
                <Col style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                    {/* Back Button */}
                    {backIcon && onBack && (
                        <Button
                            icon={backIcon}
                            onClick={handleBackClick}
                            style={{ display: 'inline-flex' }}
                        />
                    )}
                    <Space direction="vertical">
                        <Typography.Title level={4}>
                            {title}
                        </Typography.Title>
                        {subTitle && (
                            <Typography.Text type="secondary">{subTitle}</Typography.Text>
                        )}
                    </Space>
                </Col>
                {extra && (
                    <Col style={{margin: "10px"}}>
                        <div className="extra-content">{extra}</div>
                    </Col>
                )}
            </Row>

            {/* Divider */}
            {divider && <Divider style={{ margin: 0 }} />}

            {/* Children Content */}
            <div style={{ marginTop: 16 }}>{children}</div>
        </div>
    );
};

export default CustomPageHeader;
