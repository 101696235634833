import React, { useState, useEffect } from "react";
import { ReactComponent as UserIcon } from "../assets/icon_usermanagement.svg";
import { useNavigate } from "react-router-dom";
import { ReactComponent as ProfileIcon } from "../assets/icon_profile.svg";
import { ReactComponent as LogoutIcon } from "../assets/icon_logout.svg";
import ConfirmModal from "./ConfirmModal";
import useViewPort from "../services/responsiveHelper";
import { Image, Menu } from "antd";
import logo from "../assets/logo-original-white.svg";
import packageJson from "../../package.json";

const { version } = packageJson;

const SideBarContent = () => {
  const { width } = useViewPort();
  const breakpoint = 991;
  const { SubMenu } = Menu;
  const history = useNavigate();

  const [profile, setProfile] = useState();
  const [modalVisible, setModalVisible] = useState(false);

  const confirmHandleModal = () => {
    setModalVisible(false);
    localStorage.clear();
    history("/login");
  };
  useEffect(() => {
    setProfile({
      firstName: localStorage.getItem("firstName"),
      lastName: localStorage.getItem("lastName"),
    });
  }, []);
  return (
    <>
      {width > breakpoint ? (
        <div>
          <Image
            src={logo}
            width="9.7rem"
            height="3.125rem"
            preview={false}
            style={{ paddingTop: "8px", paddingLeft: "24px" }}
          />
          <Menu theme="dark" mode="inline" defaultSelectedKeys={["1"]}>
            <Menu.Item key="1" icon={<UserIcon />}>
              Users
            </Menu.Item>
          </Menu>

          <div className="sidebar-footer">
            {/* <Divider style={{ marginBottom: "0px" }} /> */}
            <hr />
            <Menu theme="dark" mode="vertical">
              <SubMenu
                theme="light"
                key="sub1"
                icon={<ProfileIcon />}
                title={
                  <span>
                    {profile?.firstName} {profile?.lastName}
                  </span>
                }
              >
                <div
                  style={{
                    background: "white",
                    color: "white",
                    marginLeft: "-2px",
                  }}
                >
                  <Menu.Item
                    theme="light"
                    key="1"
                    onClick={() => setModalVisible(true)}
                    icon={<LogoutIcon />}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      background: "white",
                      color: "black",
                      margin: "0px",
                      border: "1px solid rgb(230,230,230)",
                      boxShadow: "5px 5px 12px rgb(0 0 0/0.1)",
                      height: "56px",
                      padding: "12px",
                      alignSelf: "center",
                      borderRadius: "4px",
                    }}
                  >
                    Logout
                  </Menu.Item>
                </div>
              </SubMenu>
            </Menu>
            <hr />
            <div className="version-container">Version {version}</div>
          </div>
        </div>
      ) : (
        <div>
          <div className="drawer-logo">
            <Image
              src={logo}
              width="9.7rem"
              height="3.125rem"
              preview={false}
            />
          </div>
          <Menu
            theme="dark"
            mode="inline"
            defaultSelectedKeys={["1"]}
            className="drawer-menu"
          >
            <Menu.Item key="1" icon={<UserIcon />}>
              Users
            </Menu.Item>
            <hr />
            <SubMenu
              key="sub1"
              icon={<ProfileIcon />}
              title={
                <span style={{ lineHeight: "50px" }}>
                  {profile?.firstName} {profile?.lastName}
                </span>
              }
            >
              <Menu.Item key="2" onClick={() => setModalVisible(true)}>
                <div
                  style={{
                    color: "white",
                    marginLeft: "18px",
                  }}
                >
                  Logout
                </div>
              </Menu.Item>
            </SubMenu>
            <hr />
          </Menu>

          <div className="drawer-version">Version {version}</div>
        </div>
      )}
      <ConfirmModal
        title="Logout User?"
        content="Please confirm if you wish to Logout."
        visible={modalVisible}
        onConfirm={confirmHandleModal}
        onCancel={() => {
          setModalVisible(false);
        }}
      />
    </>
  );
};
export default SideBarContent;
