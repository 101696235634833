import React, { useState, useEffect } from "react";
import {
    Form,
    Row,
    Col,
    Input,
    Typography,
    Spin,
    Space,
    Switch
} from "antd";
import apiCall from "../../services/apiCall";
import { LoadingOutlined } from "@ant-design/icons";

import { ReactComponent as ValidationLogo } from "../../assets/icon_alert.svg";
import PageHead from "../../components/PageHead";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import useViewPort from "../../services/responsiveHelper";
import FooterMenu from "../../components/FooterMenu";
import ConfirmModal from "../../components/ConfirmModal";
import ToastNotification from "../../helpers/ToastNotification";
import { removeIdFieldStateData } from "../../helpers/StateHelper";
import WorkorderCard from "../../components/WorkorderCard";

const DEFAULT_TEST_TYPE = "TTC";
const ClientProctorForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { width } = useViewPort();
  const breakpoint = 670;
  const [form] = Form.useForm();
  const [disableForm, setDisableForm] = useState(false);
  const [workordersDetails, setWorkordersDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [workOrderLoading, setWorkOrderLoading] = useState(false);
  const {userId} = useParams();
  const [modalVisible, setModalVisible] = useState(false);
  const [modalVisibleActivate, setModalVisibleActivate] = useState(false);
  const [showSave, setShowSave] = useState(false);
  const [disableEmail, setDisableEmail] = useState(false);
  const [clientValue, setClientValue] = useState();
  const [status, setStatus] = useState();
  const [isWreOpted, setIsWreOpted] = useState(true);
  const { Text } = Typography;
  const { Search } = Input;
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 36,
        color: "rgb(0, 48, 135)",
        fontWeight: "bold",
      }}
      spin
    />
  );

  const onFinish = (formValues) => {
    delete formValues["startdate"];
    delete formValues["enddate"];
    delete formValues["woaddress"];

    if (workordersDetails.length === 0) {
      return ToastNotification("warning", "Please add at least one workorder.");
    }
    formValues.workOrders = workordersDetails.map(
      ({ workOrderNo, testType }) => ({
        workOrderNo: Number(workOrderNo),
        testType,
      })
    );
    formValues.isWreOpted = isWreOpted;

    if (clientValue?.email) {
      const payload = {};
      if (clientValue.firstName !== formValues.firstName)
        payload.firstName = formValues.firstName;
      if (clientValue.lastName !== formValues.lastName)
        payload.lastName = formValues.lastName;
      if (clientValue.email !== formValues.email)
        payload.email = formValues.email;
      if (clientValue.isWreOpted !== formValues.isWreOpted)
        payload.isWreOpted = formValues.isWreOpted;
      if (formValues.workOrders.length > 0)
        payload.workOrders = formValues.workOrders;
      if (status && status !== "Pending") {
        payload.status = status;
      }
      editClient(payload, clientValue._id);
    } else {
      formValues.clientId = workordersDetails[0].clientId;
      formValues.clientName = workordersDetails[0].clientName;
      addClient(formValues);
    }
  };
  const checkInActive = status === "InActive" ? "Inactive" : null;
  const checkStatus = status === "Active" ? "Active" : checkInActive;
  const checkClassInActive = status === "InActive" ? "switch-disabled" : null;
  const checkClassName = status === "Active" ? "switch" : checkClassInActive;

  const phShowSaveIf = showSave
    ? {
        title: clientValue?.firstName + " " + clientValue?.lastName,
        toggleStatus: checkStatus,
        onChangeSwitch: (change) => {
          if (!change) setModalVisible(true);
          else if (change) setModalVisibleActivate(true);
        },
        toggleClassname: checkClassName,
        switchDisabled: false,
        defaultChecked: status === "Active" ? true : false,
        btnAdd: "Save",
        backBtnPath: "/client-proctor",
        routeState: removeIdFieldStateData(location.state),
      }
    : {
        title: clientValue?.firstName + " " + clientValue?.lastName,
        toggleStatus: checkStatus,
        switchDisabled: true,
        onChangeSwitch: (change) => {
          if (!change) setModalVisible(true);
          else if (change) setModalVisibleActivate(true);
        },
        defaultChecked: status === "Active" ? true : false,
        toggleClassname: checkClassName,
        btnUpdate: "Edit",
        backBtnPath: "/client-proctor",
        routeState: removeIdFieldStateData(location.state),
      };
  const phShowSaveElse = {
    title: "New Client Proctor",
    btnAdd: "Save",
    backBtnPath: "/client-proctor",
    routeState: removeIdFieldStateData(location.state),
  };
  const phCheckClientValueIf = clientValue?.email
    ? phShowSaveIf
    : phShowSaveElse;
  const phCheckClientValueElse = clientValue?.email
    ? {
        title: clientValue?.firstName + " " + clientValue?.lastName,
        backBtnPath: "/client-proctor",
        routeState: removeIdFieldStateData(location.state),
      }
    : {
        title: "New Client Proctor",
        backBtnPath: "/client-proctor",
        routeState: removeIdFieldStateData(location.state),
      };
  const pageHeaderProps =
    width > breakpoint ? phCheckClientValueIf : phCheckClientValueElse;

  const showSaveIf = {
    toggleStatus: checkStatus,
    onChangeSwitch: (change) => {
      if (!change) setModalVisible(true);
      else if (change) setModalVisibleActivate(true);
    },
    toggleClassname: checkClassName,
    switchDisabled: false,
    defaultChecked: status === "Active" ? true : false,
    btnAdd: "Save",
    backBtnPath: "/client-proctor",
    routeState: removeIdFieldStateData(location.state),
  };

  const showSaveElse = {
    toggleStatus: checkStatus,
    switchDisabled: true,
    onChangeSwitch: (change) => {
      if (!change) setModalVisible(true);
      else if (change) setModalVisibleActivate(true);
    },
    defaultChecked: status === "Active" ? true : false,
    toggleClassname: checkClassName,
    btnUpdate: "Edit",
  };
  const isShowSave = showSave ? showSaveIf : showSaveElse;
  const pageFooterProps = clientValue?.email
    ? isShowSave
    : {
        btnAdd: "Save",
      };
  const confirmHandleModal = () => {
    setModalVisible(false);
    setStatus("InActive");
  };
  const confirmHandleModalActivate = () => {
    setModalVisibleActivate(false);
    setStatus("Active");
  };

  /** API calls */
  const resendInviteProctors = async () => {
    setLoading(true);
    const data = await apiCall.resendInviteProctors(clientValue.email);
    if (data.status === 200) {
      ToastNotification(
        "success",
        data ? data?.data : " Reset Activation link sent."
      );
    } else {
      ToastNotification("error", "Something went wrong.");
    }
    setLoading(false);
  };
  const getClient = async (user_Id) => {
    setLoading(true);
    const data = await apiCall.getClientById(user_Id);
    if (data.status === 200) {
      setStatus(data.data.status);
      setClientValue(data.data);
      if (data.data?.isWreOpted !== undefined)
        setIsWreOpted(data.data.isWreOpted);
      if (data.data?.workOrders) {
        data.data?.workOrders.forEach((item) => {
          getClientWorkOrders(item.workOrderNo, item.testType);
        });
      }
    } else {
      ToastNotification("error", "Error fetching User records.");
    }
    setLoading(false);
  };

  const addClient = async (formValues) => {
    setLoading(true);
    const data = await apiCall.addClientProctor(formValues);
    if (data.status === 200) {
      setClientValue(data.data);
      setShowSave(false);
      setDisableForm(true);
      setDisableEmail(true);
      ToastNotification("success", "Record saved successfully.");
      navigate(`/client-proctor-form`,{state: { ...location.state, id: data.data._id }});
    } else if (data.status === 400) {
      setLoading(false);
      form.setFields([
        {
          name: "email",
          errors: [data ? data.data : "Something went wrong."],
        },
      ]);
    } else {
      setLoading(false);
      ToastNotification("error", data?.data);
    }
    setLoading(false);
  };
  const editClient = async (formValues, userId) => {
    setLoading(true);
    const data = await apiCall.editClientProctor(formValues, userId);
    if (data.status === 200) {
      setClientValue((prev) => ({ ...prev, ...formValues }));
      setShowSave(false);
      setDisableForm(true);
      setDisableEmail(true);
      ToastNotification("success", "Record saved successfully.");
    } else {
      setLoading(false);
      ToastNotification("error", data?.data || "Error fetching User records.");
    }
    setLoading(false);
  };

  const getWorkorderDetails = async (workorderId, testType) => {
    setWorkOrderLoading(true);
    const data = await apiCall.getWorkorderDetails(workorderId);
    if (data?.status === 200) {
      if (
        workordersDetails.length === 0 ||
        data.data.clientId === workordersDetails[0].clientId
      ) {
        setWorkordersDetails((prev) => [
          ...prev,
          { ...data.data, testType: testType || DEFAULT_TEST_TYPE },
        ]);
      } else {
        ToastNotification("error", "Workorder must be from same client");
      }
    } else {
      if (showSave && data?.status === 400) {
        setDisableForm(false);
      }
      ToastNotification(
        "error",
        data ? data?.data : "Error fetching User records."
      );
      form.setFields([
        {
          name: "workOrderNo",
          errors: [data ? data?.data : "Enter valid Work Order ID."],
        },
      ]);
    }
    setWorkOrderLoading(false);
  };

  useEffect(() => {
    if (userId && userId !== null) {
      setDisableForm(true);
      setDisableEmail(true);
      getClient(userId);
    }
  }, [userId]);

  const addWorkOrder = (workOrderNo) => {
    if (!workOrderNo.trim()) {
      return ToastNotification("warning", "Please enter workorder number.");
    }

    if (isNaN(workOrderNo)) {
      return ToastNotification(
        "warning",
        "Please enter valid workorder number."
      );
    }

    if (
      !workordersDetails.some(
        (item) => Number(item.workOrderNo) === Number(workOrderNo)
      )
    ) {
      getWorkorderDetails(workOrderNo);
    } else {
      ToastNotification("warning", "Workorder already added.");
    }
  };

  const getClientWorkOrders = (workOrderNo, testType) => {
    if (
      !workordersDetails.some(
        (item) => Number(item.workOrderNo) === Number(workOrderNo)
      )
    ) {
      getWorkorderDetails(workOrderNo, testType);
    }
  };

  const removeWorkOrder = (workOrderNo) => {
    const updatedWorkOrdersData = workordersDetails.filter(
      (item) => item.workOrderNo !== workOrderNo
    );
    setWorkordersDetails(updatedWorkOrdersData);
  };

  const updateWorkOrder = (workOrderNo, testType) => {
    setWorkordersDetails((prev) =>
      prev.map((item) =>
        item.workOrderNo === workOrderNo ? { ...item, testType } : item
      )
    );
  };

  const onToggleChange = (checked) => {
    setIsWreOpted(checked);
  };

  const checkStatusPending =
    status === "Pending" ? (
      <Col span={24} className="proctor-resend-invite-container">
        <div className="login-validation-container">
          <div>
            <ValidationLogo />
          </div>

          <Space direction="vertical" size={0}>
            <span className="login-validation-text">
              Pending client activation.
            </span>
            <span className="resend-activation-link">
              <span
                className="resend-activation-link-text-client"
                onClick={resendInviteProctors}
              >
                Resend Activation Link
              </span>
            </span>
          </Space>
        </div>
      </Col>
    ) : null;

  const checkBreakpoint =
    width < breakpoint ? (
      <FooterMenu
        {...pageFooterProps}
        formName="examineticsAdminAddEditForm"
        onBtnClick={() => {
          form.submit();
        }}
        onBtnEdit={() => {
          setShowSave(true);
          setDisableForm(false);
          setDisableEmail(clientValue?.status !== "Pending" ? true : false);
        }}
      />
    ) : null;
  return loading ? (
    <Spin indicator={antIcon} className="loader" />
  ) : (
    <div className="main-container">
      <PageHead
        formName="examineticsAdminAddEditForm"
        {...pageHeaderProps}
        onBtnClick={() => {
          form.submit();
        }}
        onBtnEdit={() => {
          setShowSave(true);
          setDisableForm(false);
          setDisableEmail(clientValue?.status !== "Pending" ? true : false);
        }}
        divider={true}
      >
        <Row
          style={{
            overflow: "auto",
            minHeight: "80vh",
          }}
        >
          <Col
            xs={24}
            sm={24}
            md={8}
            lg={8}
            xl={8}
            style={{
              borderRight: "1px solid #C6C6C6",
              padding: "20px 20px 0",
            }}
          >
            <Form
              form={form}
              name="clientProctorAddEditForm"
              size="large"
              colon="false"
              scrollToFirstError="true"
              onFinish={onFinish}
              layout="vertical"
              requiredMark={true}
              className="login-form"
            >
              <Row>
                <Row>
                  <Col span={24}>
                    <Typography className="workorder-text">
                      Client Proctor Details
                    </Typography>
                  </Col>
                </Row>
                <Col span={24}>
                  <Form.Item
                    label="Client Proctor Email"
                    name="email"
                    rules={[
                      {
                        required: true,
                        type: "email",
                        message: "Please enter valid Email.",
                      },
                    ]}
                    initialValue={clientValue?.email}
                  >
                    <Input
                      placeholder="Ex: jonathan@gmail.com"
                      disabled={disableEmail}
                      autoFocus
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify="space-between">
                <Col span={24}>
                  <Form.Item
                    label="First Name"
                    name="firstName"
                    rules={[
                      {
                        required: true,
                        message: "Please enter First Name.",
                      },
                    ]}
                    initialValue={clientValue?.firstName}
                  >
                    <Input placeholder="Ex: Tanya " disabled={disableForm} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label="Last Name"
                    name="lastName"
                    rules={[
                      {
                        required: true,
                        message: "Please enter Last Name.",
                      },
                    ]}
                    initialValue={clientValue?.lastName}
                  >
                    <Input placeholder="Ex: Jonathan" disabled={disableForm} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Row
                    justify="space-between"
                    className="wre-opt-in-out-container"
                  >
                    <Col>
                      <Space direction="vertical" size={0}>
                        <Text strong>
                          Opt-In for WRE (Work Relatedness Evaluation)
                        </Text>
                        <Text type="secondary">
                          Toggle the switch to enable or disable WRE
                        </Text>
                      </Space>
                    </Col>
                    <Col>
                      <Switch
                        defaultChecked={isWreOpted}
                        onChange={onToggleChange}
                        disabled={disableForm}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              {checkStatusPending}
            </Form>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={16}
            lg={16}
            xl={16}
            style={{
              padding: "20px 20px 0",
            }}
          >
            <Row>
              <Col span={24}>
                <Typography className="workorder-text">Workorders</Typography>
              </Col>
              <Col span={24} className="workorder-search-input">
                <Search
                  placeholder="Enter Workorder"
                  enterButton="Add"
                  size="large"
                  disabled={disableForm}
                  loading={workOrderLoading && !disableForm}
                  onSearch={addWorkOrder}
                />
              </Col>
              <Col span={24}>
                <Spin spinning={workOrderLoading}>
                  <Space
                    direction="vertical"
                    size={"middle"}
                    style={{ width: "100%" }}
                  >
                    {workordersDetails.map((data) => (
                      <WorkorderCard
                        data={data}
                        removeWorkOrder={removeWorkOrder}
                        key={data.workOrderNo}
                        disabled={disableForm}
                        updateWorkOrder={updateWorkOrder}
                      />
                    ))}
                  </Space>
                </Spin>
              </Col>
            </Row>
          </Col>
        </Row>
      </PageHead>
      {checkBreakpoint}
      <ConfirmModal
        title="De-activate user?"
        content="Please confirm if you wish to de-activate this user."
        visible={modalVisible}
        onConfirm={confirmHandleModal}
        onCancel={() => {
          setModalVisible(false);
          setStatus("Active");
        }}
      />
      <ConfirmModal
        title="Activate user?"
        content="Please confirm if you wish to Activate this user."
        visible={modalVisibleActivate}
        onConfirm={confirmHandleModalActivate}
        onCancel={() => {
          setModalVisibleActivate(false);
          setStatus("InActive");
        }}
      />
    </div>
  );
};
export default ClientProctorForm;
