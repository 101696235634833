import React from "react";
import { Layout } from "antd";
import MainHeader from "../../components/MainHeader";
// import SideBar from "../../components/Sidebar";

const { Content } = Layout;

const HomePage = (props) => {
    const { children } = props;

    return (
        <Layout style={{ overflow: "hidden" }}>
            {/* <SideBar /> */}
            <Layout>
                <MainHeader />
                <Content>
                    <div className="site-layout-background">{children}</div>
                </Content>
            </Layout>
        </Layout>
    );
};

export default HomePage;
