import React from "react";
import { List, Typography } from "antd";
import { ReactComponent as EmailIcon } from "../assets/icon_mail.svg";
import { ReactComponent as PhoneIcon } from "../assets/icon_phone.svg";
import { useNavigate } from "react-router-dom";

const ListItems = (props) => {
  const { data } = props;
  const navigate = useNavigate();
  return (
    <List
      itemLayout="vertical"
      size="large"
      dataSource={data}
      key={(resData) => resData._id}
      renderItem={(item) => {
        let color;
        if (item.status === "Active") color = "rgb( 98, 181, 229)";
        else if (item.status === "InActive") color = "rgb( 154,154,154)";
        else color = "rgb( 232,119,34)";
        return (
          <>
            <hr
              style={{
                border: "1px solid rgb(198,198,198)",
                marginLeft: "0",
              }}
            />
            <List.Item key={item.title}>
              <List.Item.Meta
                onClick={() => {
                  navigate(`/examinetics-admin-form/${item._id}`, { state: { id: item._id } });
                }}
                title={
                  <span>
                    <b>
                      {item.firstName} {item.lastName}
                    </b>

                    <span style={{ float: "right" }}>
                      <span
                        className="status"
                        style={{
                          backgroundColor: color,
                        }}
                      />
                      <span className="status-text">
                        {item.status ? item.status : "Pending"}
                      </span>
                    </span>
                  </span>
                }
              />
              <span>
                <Typography style={{ marginBottom: "7px" }}>
                  <EmailIcon /> {item.email}
                </Typography>
                {item.phone ? (
                  <Typography>
                    <PhoneIcon /> {item.phone}
                  </Typography>
                ) : (
                  <Typography style={{ marginLeft: "5px" }}>Not Set</Typography>
                )}
              </span>
            </List.Item>
          </>
        );
      }}
      className="list"
    />
  );
};

export default ListItems;
