import React from "react";
import "../../styles/login.css";
import apiCall from "../../services/apiCall";
import { ReactComponent as Logo } from "../../assets/logo-original.svg";
import { ReactComponent as EyeOn } from "../../assets/icon_eye_on.svg";
import { ReactComponent as EyeOff } from "../../assets/icon_eye_off.svg";
import ToastNotification from "../../helpers/ToastNotification";
import { Form, Input, Button, Card, Typography, Row, Col } from "antd";
import { useNavigate } from "react-router-dom";
import Copyright from "../../components/Copyright";
import { decodeJwt } from 'jose';

const CreatePassword = () => {
    const [form] = Form.useForm();
    const history = useNavigate();
    const token = window.location.pathname.split("/").pop();
    const decodedToken = decodeJwt(token);

    const onFinish = async (values) => {
        if (values.newPassword == values.confirmPassword) {
            /* eslint eqeqeq: 0 */
            const response =
                decodedToken?.type === "Proctor"
                    ? await apiCall.activatePasswordProctors(
                          token,
                          values.newPassword
                      )
                    : await apiCall.activatePassword(token, values.newPassword);
            if (response) {
                history({
                    pathname:
                        decodedToken?.type === "Proctor"
                            ? "/client-activated"
                            : "/login",
                    state: { fromResetPassword: true }
                });
            } else {
                ToastNotification("error", "Something went wrong.");
            }
        } else {
            form.setFields([
                {
                    name: "confirmPassword",
                    errors: ["Passwords don't match."]
                }
            ]);
        }
    };
    const validatePassword = (_rule, value, callback) => {
        const idealPass =
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,25}$/;
        if (value) {
            if (value.match(idealPass)) {
                callback();
            } else {
                callback("Your password does not meet the required criteria.");
            }
        }
    };
    return (
        <div className="login-container">
            <Row align="middle" style={{ width: "100%" }}>
                <Col
                    xs={{ span: 20, offset: 2 }}
                    sm={{ span: 18, offset: 3 }}
                    md={{ span: 16, offset: 4 }}
                    lg={{ span: 14, offset: 5 }}
                    xl={{ span: 10, offset: 7 }}
                    className="box-modal"
                >
                    <Row justify="center" align="top">
                        <div className="logo-container">
                            <Logo />
                        </div>
                    </Row>
                    <Card className="login-box">
                        <Form
                            form={form}
                            requiredMark={false}
                            name="normal_login"
                            layout="vertical"
                            className="login-form"
                            initialValues={{
                                remember: true
                            }}
                            onFinish={onFinish}
                        >
                            <Typography className="login-title">
                                {" "}
                                Create an Account
                            </Typography>
                            <Typography className="reset-password-info">
                                Create a password that is 8 to 25 characters
                                long, includes at least one lowercase letter,
                                one uppercase letter, one digit, and one special
                                character (!@#$%^&*).
                            </Typography>

                            <Form.Item
                                label="New Password"
                                name="newPassword"
                                rules={[
                                    {
                                        required: true,
                                        message: "Enter your New Password."
                                    },
                                    { validator: validatePassword }
                                ]}
                            >
                                <span
                                    className="password"
                                    style={{ height: "35.14px" }}
                                >
                                    <Input.Password
                                        placeholder="Password"
                                        autoFocus
                                        iconRender={(visible) =>
                                            visible ? <EyeOn /> : <EyeOff />
                                        }
                                    />
                                </span>
                            </Form.Item>
                            <Form.Item
                                label="Confirm Password"
                                name="confirmPassword"
                                dependencies={["newPassword"]}
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: "Enter your Password again."
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(rule, value) {
                                            if (
                                                !value ||
                                                getFieldValue("newPassword") ===
                                                    value
                                            ) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(
                                                "The confirm passwords that you entered do not match!"
                                            );
                                        }
                                    })
                                ]}
                            >
                                <span
                                    className="password"
                                    style={{ height: "35.14px" }}
                                >
                                    <Input.Password
                                        placeholder="Password"
                                        iconRender={(visible) =>
                                            visible ? <EyeOn /> : <EyeOff />
                                        }
                                    />
                                </span>
                            </Form.Item>

                            <Form.Item>
                                <div className="login-button-container">
                                    <Button
                                        type="primary"
                                        shape="round"
                                        htmlType="submit"
                                    >
                                        Create an Account
                                    </Button>
                                </div>
                            </Form.Item>
                        </Form>
                    </Card>
                    <Row justify="center" align="bottom">
                        <Copyright />
                    </Row>
                </Col>
            </Row>
        </div>
    );
};
export default CreatePassword;
