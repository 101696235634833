import React from "react";
import ResponsiveContext from "./responsiveContext";


const useViewPort = () => {
    const { width, height } = React.useContext(ResponsiveContext);
    return { width, height };
};

export default useViewPort;
